<template>
    <a href="javascript:0;"
        data-toggle="popover"
        :title="getTitleText"
        data-trigger="hover"
        data-placement="top"
        data-html="true"
        :data-content="getPriceText">
        <i class="fal fa-info-circle"></i>
    </a>

</template>
<script>
    import V from 'voUtils/V.js';
    import DateEx from 'voUtils/DateEx.js';

    export default {
    	voVueComponent: 'price-help',
    	/*props: {
    		pricelist: Array
    	},*/
    	mounted: function() {
    		$('[data-toggle="popover"]').popover();
    	},
    	computed: {
    		getTitleText: function() {
    			var titleText = '';
    			/*if (this.pricelist) {
    				titleText = 'Obligatorische Nebenkosten';
    			} else {
    				titleText = 'Zusätzliche Kosten';
    			}*/
    			titleText = 'Zusätzliche Kosten';
    			return titleText;
    		},
    		getPriceText: function() {
    			//console.log('pricelist::' + this.pricelist);
    			var priceText = '';
    			priceText += "<ul>";
    			priceText += "<li>zzgl.</li>";
    			priceText += '<li>Endreinigung</li>';
    			priceText += '<li>Buchungsgebühr</li>';
    			priceText += '<li>Kurtaxe</li>';
    			priceText += "</ul>";

    			return priceText;
    		}
    	}
    };
</script>